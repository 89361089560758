
import Main from "./components/Main";
import Navbar from "./components/Navbar";
import NotFound from './components/NotFound';
import About from "./components/About";
import Footer from './components/Footer';
import {BrowserRouter,Routes, Route} from 'react-router-dom';
import GalleryMUI from "./components/Gallery_MUI";

import ContactForm from "./components/Contact";

function App() {
  return (
    <>
        <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Main />}>            
          </Route>
            <Route path='/gallery' element={<GalleryMUI />}>    
            </Route>  
            <Route path='/about' element={<About />}>            
            </Route>     
            <Route path='/contact' element={<ContactForm />}>            
            </Route>
            <Route path="*" element={<NotFound />}>
          </Route>
        </Routes>
        <Footer />
        </BrowserRouter>
    </>
  );
}

export default App;
