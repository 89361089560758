

export default function ContactForm() {

  return (
    <form name='contactForm' className="cf" method="post">
          
          {/* hidden input for react netlify form */}
          <input type="hidden" name="form-name" value="contactForm" className="form-field" />
          <label htmlFor="cf-name" className="visuallyhidden">Your Name</label>
          <input name='name' required maxLength={55} placeholder="name" className="form-field" autoComplete="name" id="cf-name"/>
          <label htmlFor="cf-email" className="visuallyhidden">Your Email</label>
          <input name='email' type='email' required maxLength={55} placeholder="email" className="form-field" autoComplete="email" id="cf-email"/>
          <label htmlFor="cf-message" className="visuallyhidden">Your Message</label>
          <textarea
            required
            name='message'
            maxLength = {1000} 
            className="form-field"
            placeholder="your message..."
            id='cf-message'
          />
          <button type="submit" className="form-field">send</button>
         
    </form>
  );
}