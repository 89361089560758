

import p1 from "../img/1.jpg";
import p2 from "../img/2.jpg";
import p3 from "../img/3.jpg";
import p4 from "../img/4.jpg";
import p5 from "../img/5.jpg";
import p6 from "../img/6.jpg";
import p7 from "../img/7.jpg";
import p8 from "../img/8.jpg";

import p10 from "../img/10.jpg";
import p11 from "../img/11.jpg";
import p12 from "../img/12.jpg";
import p13 from "../img/13.jpg";
import p14 from "../img/14.jpg";
import p15 from "../img/15.jpg";
import p16 from "../img/16.jpg";
import p17 from "../img/17.jpg";
import p18 from "../img/18.jpg";

export const images = 
[
  {
    src: p17,
    original: p17,
    width: 1136,
    height: 1438,
    alt: "Fleabage & Rimedewy, oil on canvas.",
    caption: "Fleabage & Rimedewy, oil on canvas.",
  },

  {
    src: p2,
    original: p2,
    width: 674,
    height: 1024,
    alt: "woman with flower in hair, Oil on Archer paper.",
    caption: "woman with flower in hair, Oil on Archer paper.",
  },
  {
    src: p3,
    original: p3,
    width: 816,
    height: 1024,
    alt: "short hair woman, oil on painting.",
    caption: "short hair woman, oil on painting.",
  },
  {
    src: p16,
    original: p16,
    width: 1440,
    height: 1440,
    alt: "Diana, Oil on canvas.",
    caption: "Diana, Oil on canvas.",
  },
  {
    src: p4,
    original: p4,
    width: 793,
    height: 1024,
    alt: "Kirill, Oil on canvas.",
    caption: "Kirill, Oil on canvas.",
  },
  {
    src: p5,
    original: p5,
    width: 938,
    height: 1024,
    alt: "Young man with hat, oil on Archer paper.",
    caption: "Young man with hat, oil on Archer paper.",
  },
  {
    src: p6,
    original: p6,
    width: 819,
    height: 1024,
    alt: "Olya, Oil on canvas.",
    caption: "Olya, Oil on canvas.",
  },
  {
    src: p7,
    original: p7,
    width: 663,
    height: 985,
    alt: "Rick the muse, oil on canvas",
    caption: "Rick the muse, oil on canvas",
  },
  {
    src: p8,
    original: p8,
    width: 659,
    height: 1024,
    alt: "girl with checkered coat, Oil on canvas.",
    caption: "girl with checkered coat, Oil on canvas.",
  },

  {
    src: p10,
    original: p10,
    width: 786,
    height: 1024,
    alt: "Woman with scarf, Oil on canvas.",
    caption: "Woman with scarf, Oil on canvas.",
  },
  {
    src: p11,
    original: p11,
    width: 793,
    height: 1024,
    alt: "Woman portrait, Oil on canvas.",
    caption: "Woman portrait, Oil on canvas.",
  },
  {
    src: p12,
    original: p12,
    width: 834,
    height: 1024,
    alt: "Beard man, Oil on canvas",
    caption: "Beard man, Oil on canvas",
  },
  {
    src: p13,
    original: p13,
    width: 1024,
    height: 1024,
    alt: "Zlatoslava, Oil on canvas.",
    caption: "Zlatoslava, Oil on canvas.",
  },
  {
    src: p14,
    original: p14,
    width: 396,
    height: 632,
    alt: "Lady Long Limbs, Oil on canvas.",
    caption: "Lady Long Limbs, Oil on canvas.",
  },
  {
    src: p15,
    original: p15,
    width: 724,
    height: 1024,
    alt:"Juli, Oil on canvas, 50x70 cm.",
    caption:"Juli, Oil on canvas, 50x70 cm.",
  },


  {
    src: p18,
    original: p18,
    width: 2048,
    height: 1447,
    alt: "Ioana, oil on canvas 70×100cm.",
    caption: "Ioana, oil on canvas 70×100cm.",
  },
  {
    src: p1,
    original: p1,
    width: 685,
    height: 1024,
    alt: "Girl with big glasses, Oil on canvas.",
    caption: "Girl with big glasses, Oil on canvas.",
  },
];
