import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

export default function Footer() {
    

    return (
        <footer>

                <a rel="nofollow" href="/#" onClick={window.print} className="printer">
                    <FontAwesomeIcon icon={faPrint} />
                    <span>print</span>              
                </a>

                <div className='footer-right'>
                        <small> 2023 &#169; Géza Maurer.</small>
                        <small>All rights reserved.</small>
                </div>
    
        </footer>
    )
}