
import { Link } from 'react-router-dom';
import workscollage from '../img/paintings-collage.jpg';


export default function Main() {



    return (
        <>
        <div id='intro-image-container'>
          <Link to="/gallery" >
            <img src={workscollage} width="1000" height='1000' alt="" id='intro-image' />
          </Link>
        </div>
        </>
    );
}