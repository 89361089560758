import React from "react";
import { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images } from "./Photos";

import Masonry from '@mui/lab/Masonry';


export  default function GalleryMUI() {

  const [index, setIndex] = useState(-1);
  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

   // Note: in development strick mode the lightbox modal not working correctly - first instance forever loading symbol
  const handleClick = (e) => {
    const i = [...e.target.parentElement.parentElement.children].indexOf(e.target.parentElement);
    setIndex(i);
}
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);


  return (
    <>
      <Masonry columns={2} spacing={5} onClick={handleClick} sx={{marginInline:"auto", width:"100%"}}>
        {itemData.map((item, index) => (
          <div key={index} >
            <img 
              src={`${item.img}?w=162&auto=format`}
              srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: 'block',
                width: '100%',
              }}
              className="masonry-img"
            />
          </div>
        ))}
      </Masonry>

    {!!currentImage && (
          /* @ts-ignore */
          <Lightbox
            mainSrc={currentImage.original}
            imageTitle={currentImage.caption}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.original}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.original}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
      )}
    </>


  );
}

const itemData = images.map((item)=>{
  return {"img" : item.src, "title" : item.alt }
  }
);

