
import introImg from '../img/04_intro.jpg'

export default function About() {
    return (
        <>
          <div id='main-container'>
            <div id='about-image-container'>
              <img src={introImg} width="640" height='1024' alt="" id='about-image'/>
            </div>
            <div className='about-container'>
              <p>géza maurer</p>
              <p>realist painting</p>
              <address>barcelona, spain</address>
            </div>
          </div>
        </>
    );
}