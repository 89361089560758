import {NavLink} from 'react-router-dom';

export default function Navbar() {

    return (
        <div className="nav-container">
            <NavLink to="/" className={({isActive}) => isActive? "nav-active nav-name" :"nav-name"}>
                    <h1>géza maurer</h1>
            </NavLink>
                <ul className="nav-items">
                    <li>
                        <NavLink to="/gallery" className={({isActive}) => isActive? "nav-active nav-item" :"nav-item"}>
                            gallery
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" className={({isActive}) => isActive? "nav-active nav-item" :"nav-item"}>
                            about
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" className={({isActive}) => isActive? "nav-active nav-item" :"nav-item"}>
                            contact
                        </NavLink>
                    </li>
                </ul>

    </div>

    );
}